<template>
  <div class="macfinder">
    <h1>{{ $t('views.tools.ap_list.access_point_list') }}</h1>
    <Loading :data="[baseList, apList]">
      {{ $t('views.tools.ap_list.state', { date: lastGen }) }}<br/>
      <span v-if="apList">{{ $t('views.tools.ap_list.stock_count', { count: apList.length }) }}</span>
      <b-form-group label="Filter:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selectedFilter"
          :aria-describedby="ariaDescribedby"
          name="catFilter"
          switches
          @change="advFilter"
        >
          <b-form-checkbox v-bind:key="i.type" :title="i.description" v-for="i in Object.values(errorTypes)"
                           :value="i.type">{{ i.label }}
            <b-badge :variant="i.weight > 10?'danger':'warning'">
              {{ itemsByErrorType[i.type] ? itemsByErrorType[i.type].length : '0' }}
            </b-badge>
          </b-form-checkbox>
          <b-form-checkbox value="down">{{ $t('views.tools.ap_list.down') }}
            <b-badge variant="danger">{{ down.length }}</b-badge>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <FilterInput v-model="filter"></FilterInput>
      <b-card class="shadow">
        <b-pagination v-if="baseList && baseList.length > per_page && filter === ''" :total-rows="baseList.length"
                      :per-page="per_page" v-model="current_page" align="center"></b-pagination>
        <b-table responsive striped :fields="res_fields" :filter="filter" :items="baseList"
                 :per-page="filter===''?per_page:null"
                 :current-page="filter === ''?current_page:null">
          <template v-slot:head(power_consumption)="data">
            <span class="text-nowrap">
            {{ data.label }}
              <netvs-icon icon="power"></netvs-icon>
            </span>
          </template>
          <template v-slot:cell(mac)="item">
            <code>{{ item.item.mac }}</code>
          </template>
          <template v-slot:cell(status)="item">
            <ul>
              <li v-if="!item.item.up">{{ $t('views.tools.ap_list.down') }}</li>
              <template v-if="Object.keys(item.item.errors).length !== 0">
                <li v-bind:key="i.description" v-for="i in item.item.errors">{{ errorTypes[i.type].label }}:
                  {{ i.description }}
                </li>
              </template>
            </ul>
          </template>
          <template v-slot:cell(nd_module_type)="item">
            <span class="text-nowrap">{{ item.item.nd_module_type }}</span>
          </template>
          <template v-slot:cell(nd_device_fqdn)="item">
            <b-link :to="'/dnsvs/fqdns/'+item.item.nd_device_fqdn+'/records'">{{ item.item.nd_device_fqdn }}</b-link>
          </template>
          <template v-slot:cell(power_consumption)="item">
            <template v-if="!isNaN(parseFloat(item.item.power_consumption))">{{ item.item.power_consumption }} {{ $t('system.watt')}}
            </template>
            <template v-else>{{ item.item.power_consumption }}</template>
          </template>
          <template v-slot:cell(nd_connection_speed)="item">
            <template v-if="!isNaN(parseFloat(item.item.nd_connection_speed))">{{ item.item.nd_connection_speed }} /
              <span v-if="item.item.snmp_connection_speed">{{ item.item.snmp_connection_speed }}</span><span
                v-else>?</span> {{ $t('views.tools.ap_list.mbits') }}
            </template>
            <template v-else>{{ item.item.nd_connection_speed }}</template>
          </template>
          <template v-slot:cell(nd_connection_switch)="item">
            <template v-if="item.item.nd_connection_switch">{{ item.item.nd_connection_switch }},
              {{ $t('views.tools.ap_list.port') }}
              {{ item.item.nd_connection_port }}
            </template>
          </template>
          <template v-slot:cell(up)="item">
            <template v-if="item.item.up && Object.keys(item.item.errors).length === 0">
              <netvs-icon class="text-success" icon="monitoring_up"></netvs-icon>
            </template>
            <template v-else>
              <netvs-icon class="text-danger" icon="monitoring_down"></netvs-icon>
            </template>
          </template>
        </b-table>
        <b-pagination v-if="baseList && baseList.length > per_page && filter === ''" :total-rows="baseList.length"
                      :per-page="per_page" v-model="current_page" align="center"></b-pagination>
      </b-card>
    </Loading>
  </div>
</template>
<script>
import APListService from '@/api-services/aplist.service'
import Loading from '@/components/Loading'
import FilterInput from '@/components/FilterInput'
import apiutil from '@/util/apiutil'
import dateutil from '@/util/dateutil'

export default {
  name: 'APList',
  components: { FilterInput, Loading },
  computed: {
    res_fields() {
      return [
        {
          key: 'name',
          label: this.$t('system.name'),
          sortable: true
        },
        {
          key: 'nd_device_fqdn',
          label: this.$t('views.tools.ap_list.device_fqdn'),
          sortable: true
        },
        {
          key: 'nd_module_type',
          label: this.$t('views.tools.ap_list.module_type'),
          sortable: true
        },
        {
          key: 'nd_connection_switch',
          label: this.$t('views.tools.ap_list.switch'),
          sortable: true
        },
        {
          key: 'nd_connection_type',
          label: this.$t('views.tools.ap_list.connection_type'),
          sortable: true
        },
        {
          key: 'nd_wall_port',
          label: this.$t('views.tools.ap_list.socket'),
          sortable: true
        },
        {
          key: 'nd_connection_speed',
          label: this.$t('views.tools.ap_list.connection_speed'),
          sortable: true
        },
        {
          key: 'power_consumption',
          label: this.$t('views.tools.ap_list.power_consumption'),
          sortable: true
        },
        {
          key: 'mac',
          label: this.$t('system.mac_address'),
          sortable: true
        },
        {
          key: 'serial',
          label: this.$t('views.tools.ap_list.serial_number'),
          sortable: true
        },
        {
          key: 'up',
          label: this.$t('views.tools.ap_list.status'),
          sortable: false
        },
        {
          key: 'status',
          label: this.$t('views.tools.ap_list.error'),
          sortable: false
        }
      ]
    }
  },
  data() {
    return {
      apList: null,
      per_page: 100,
      filter: '',
      current_page: 1,
      lastGen: null,
      speedErrors: {},
      errorTypes: {},
      itemsByErrorType: {},
      selectedFilter: [],
      down: [],
      baseList: null
    }
  },
  methods: {
    async fetch() {
      const res = await APListService.fetchList(this.$store.state)
      this.errorTypes = apiutil.dict_by_value_of_array(res.data.errors, 'type')
      this.lastGen = dateutil.format_date(dateutil.parse_date(res.data.timestamp))
      this.apList = res.data.data
      this.itemsByErrorType = apiutil.dict_of_lists_by_value_of_array_l2_l2_array(res.data.data, 'errors', 'type')
      window.console.debug(this.itemsByErrorType)
      for (const item of this.apList) {
        item._cellVariants = {}
        if (Object.keys(item.errors).length !== 0) {
          let errorCum = 0
          for (const i of item.errors) {
            errorCum += this.errorTypes[i.type].weight
          }
          if (errorCum > 0) {
            item._rowVariant = 'warning'
          }
          if (errorCum > 10) {
            item._rowVariant = 'danger'
          }
        }
        if (!item.up) {
          item._rowVariant = 'danger'
          this.down.push(item)
        }
      }
      this.baseList = this.apList
    },
    advFilter() {
      this.baseList = null
      const res = []
      let baseList = this.apList
      if (this.selectedFilter.includes('down')) {
        baseList = this.down
      }
      if (this.selectedFilter.length === 0 || (this.selectedFilter.length === 1 && this.selectedFilter[0] === 'down')) {
        this.baseList = baseList
        return
      }
      for (const i of baseList) {
        let affections = 0
        for (const j of this.selectedFilter) {
          if (j === 'down') {
            if (!i.up) {
              affections++
            }
            continue
          }
          for (const k of i.errors) {
            if (k.type === j) {
              affections++
            }
          }
        }
        if (affections === this.selectedFilter.length) {
          res.push(i)
        }
      }
      this.baseList = res
    }
  },
  async mounted() {
    await this.fetch()
  }
}
</script>
